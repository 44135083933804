import { type DBSchema, openDB } from 'idb';

export type NewPost = {
	content: string;
	draftId: number;
	image: File | null;
	securities: string[];
	tags: string[];
	title: string;
};

export interface IDBSchema extends DBSchema {
	newPosts: {
		key: number;
		value: NewPost;
	};
}

export type IDB = Awaited<ReturnType<typeof useIDB>>;

export async function useIDB() {
	return await openDB<IDBSchema>('tickrr-idb', undefined, {
		upgrade(db) {
			// New post object.
			if (!db.objectStoreNames.contains('newPosts')) {
				db.createObjectStore('newPosts', { keyPath: 'draftId' });
			}
		}
	});
}
